@use '@angular/material' as mat;
@import 'variables';

@mixin custom-material-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // Menu Items
  .mat-menu-item {
    font-size: 0.8rem;

    mat-icon {
      font-size: 1.5rem;
    }
  }

  // Button Toggle Group
  .mat-button-toggle-group {
    border: 1px solid black;
    height: 3.7rem !important;
    margin-bottom: 1.25rem;
  }

  .mat-button-toggle {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-contrast-color-from-palette($primary, 0.87);
    height: 100%;
    min-width: 6.25rem;
    border: none;
  }

  .mat-button-toggle-group-appearance-standard {
    border-radius: .75rem !important;
  }

  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: white;

    & > button > span {
      color: mat.get-contrast-color-from-palette($primary, 0.38) !important;
      font-weight: 400;
    }
  }

  .mat-button-toggle-disabled .mat-button-toggle-button {
    color: mat.get-contrast-color-from-palette($primary, 0.38) !important;
    font-weight: 400;
  }

  .mat-button-toggle-button {
    height: 100%;
    width: 100%;
    padding: 0 !important;

    .mat-focus-indicator {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
  }

  .mat-button-toggle-checked {
    height: 100%;
    min-width: 6.25rem;
    background-color: white;
    border: none;

    .mat-focus-indicator {
      width: 100%;
      height: 100%;
    }

    .mat-button-toggle-label-content {
      font-weight: 500;
      color: mat.get-color-from-palette($primary);
    }
  }

  .mat-button-toggle-label-content {
    width: auto;
    height: auto;
    padding: 0;
  }

  // Mat Button
  .mat-button-wrapper {
    color: inherit;
  }

  // Mat Slider
  .mat-slide-toggle-content {
    font-family: Gilroy, Roboto, serif;
  }

  // Mat table
  .mat-table {
    background-color: transparent !important;

    .mat-header-cell {
      font-size: 1rem;
      font-weight: 400;
    }

    .mat-header-row {
      border: none;
    }

    .mat-cell {
      font-size: 1rem;
      font-weight: 400;
      word-wrap: anywhere;
    }

    mat-row.mat-row {
      cursor: pointer;
      border: 1px solid transparent;
      background-color: transparent;

      &:hover {
        background-color: white;
        border-top: 1px solid mat.get-contrast-color-from-palette($primary, 0.12);
        border-bottom: 1px solid mat.get-contrast-color-from-palette($primary, 0.12);
      }
    }
  }

  .mat-error {
    color: mat.get-color-from-palette($warn);
  }

  // Mat Tooltip
  .mat-tooltip {
    font-size: .9rem;
    background-color: white;
    padding: 1rem !important;
    color: mat.get-color-from-palette($primary) !important;
    border: 1px solid mat.get-color-from-palette($primary);
  }

  // Mat Card
  .mat-card {
    width: auto;
    background-color: mat.get-color-from-palette($accent);
    margin: 1rem $header-padding-right 1rem $header-padding-left;
    font-size: 1rem;
    border-radius: 1.25rem !important;
    padding: $modeling-card-padding !important;

    mat-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.25rem;
      margin-bottom: 0;

      mat-icon {
        margin-right: 1rem;
      }

      & > h2 {
        font-size: 1.4rem !important;
        color: mat.get-contrast-color-from-palette($primary, 0.87) !important;
      }

      & > * > h2 {
        font-size: 1.4rem !important;
        color: mat.get-contrast-color-from-palette($primary, 0.87) !important;
      }
    }

    & > *:not(mat-card-title) {
      font-size: 1rem !important;
    }

    .mat-card-header-text {
      width: 100%;
    }
  }

  // Mat Toolbar
  .mat-toolbar {
    padding: 2rem 4rem !important;
    height: auto !important;
  }

  // Mat Form Field
  .mat-form-field {
    font-size: 1rem;
    width: 100%;
    margin-top: 2rem;
  }

  .mat-form-field-infix {
    width: auto !important;
  }

  .mat-form-field-empty.mat-form-field-label {
    font-size: 1rem;
  }

  .mat-form-field-label-wrapper {
    font-size: 1.25rem;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    font-size: 1.25rem;
  }

  .mat-menu-item {
    :not(mat-icon) {
      font-size: 1rem;
    }
  }

  .mat-drawer-container {
    background-color: mat.get-color-from-palette($accent);
  }

  // Mat Dialog
  .mat-dialog-container {
    margin: 1rem auto;
    font-size: 1rem;
    border-radius: 1.25rem !important;
    background-color: mat.get-color-from-palette($accent);
  }

  // Mat Lists
  .mat-list-base {
    padding-top: 0;
  }

  .mat-list-item-content {
    font-size: 1rem;
    word-wrap: anywhere !important;
  }

  .mat-list-item {
    height: 2rem !important;
  }

  // Mat Select
  .mat-option-text {
    font-size: 0.9rem;
  }

  .mat-select-value {
    font-size: 0.9rem;
  }

  // Mat Paginator
  .mat-paginator {
    background-color: transparent !important;
    font-size: 0.9rem !important;
    display: flex !important;
  }

  .mat-paginator-page-size-select {
    width: 3rem !important;
    margin-top: 0 !important;
  }

  .mat-paginator-range-actions {
    * {
      color: mat.get-contrast-color-from-palette($primary, 0.54) !important;
    }
  }

  fa-icon {
    color: mat.get-color-from-palette($warn);
    margin-right: 1rem !important;
  }

  a {
    white-space: normal;
  }

  .main-content {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .full-height {
    height: 100%;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }

  .col {
    display: flex;
    width: 100%;
    height: 100%;
    margin-right: 4rem;
    align-items: center;
    justify-content: flex-start;
  }

  .col:last-child {
    margin-right: 0;
  }

  .mat-snack-bar-container {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    background-color: mat.get-color-from-palette($accent);
    border-style: solid;
    border-color: mat.get-color-from-palette($primary);
    border-width: 0.2rem;
    color: mat.get-color-from-palette($primary);
  }
}
