/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
  font-size: 16px; // Big screens

  // Medium screens
  @media screen and (max-device-height: 1400px) {
    font-size: 14px;
  }

  // Normal screen
  @media screen and (max-device-height: 1200px) {
    font-size: 13px;
  }

  // Normal screen
  @media screen and (max-device-height: 1080px) {
    font-size: 12px;
  }

  // Small screen
  @media screen and (max-device-height: 900px) {
    font-size: 11px;
  }

}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

h2 {
  font-size: 1.4rem;
  font-weight: 500;
}

h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

h5 {
  font-size: 1.1rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Gilroy", Roboto, "Helvetica Neue", sans-serif;
}

/* Hide arrows in input text*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.tooltip {
  margin-left: 1rem;
}

.recyclability-help-box {
  white-space: pre-line;
}
