@import "../fonts";

@mixin kraftheinz-fonts() {
  body > * {
    font-family: "Gotham", Roboto, "Helvetica Neue", sans-serif !important;
  }

  *:not(mat-icon) {
    font-family: "Gotham", Roboto, "Helvetica Neue", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Gotham", Roboto, "Helvetica Neue", sans-serif;
    font-weight: 400;
    margin: 0 !important;
    word-wrap: anywhere;
  }
}
