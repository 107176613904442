@import "src/styles/fonts";

@mixin lactalis-fonts() {
  body > * {
    font-family: "EuclidFlex", "Gilroy", Roboto, "Helvetica Neue", sans-serif !important;
    font-size: large;
  }

  *:not(mat-icon) {
    font-family: "EuclidFlex", "Gilroy", Roboto, "Helvetica Neue", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "EuclidFlex", "Gilroy", Roboto, "Helvetica Neue", sans-serif;
    font-weight: 400;
    margin: 0 !important;
    word-wrap: anywhere;
  }

  .action-button {
    * {
      font-family: Gilroy, Roboto, "Helvetica Neue", sans-serif !important;
    }
  }
}
