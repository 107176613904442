@use '@angular/material' as mat;

/* For use in src/lib/core/theming/_palette.scss */
$md-367c: (
  50 : #f4faec,
  100 : #e3f2cf,
  200 : #d1eaaf,
  300 : #bee18e,
  400 : #b0da76,
  500 : #a2d45e,
  600 : #9acf56,
  700 : #90c94c,
  800 : #86c342,
  900 : #75b931,
  A100 : #ffffff,
  A200 : #e4ffcc,
  A400 : #caff99,
  A700 : #bdff80,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-369c: (
  50 : #ecf4e2,
  100 : #d0e4b7,
  200 : #b0d387,
  300 : #90c156,
  400 : #79b332,
  500 : #61a60e,
  600 : #599e0c,
  700 : #4f950a,
  800 : #458b08,
  900 : #337b04,
  A100 : #c5ffa8,
  A200 : #a3ff75,
  A400 : #81ff42,
  A700 : #70ff29,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-349c: (
  50 : #e0ede7,
  100 : #b3d2c3,
  200 : #80b49b,
  300 : #4d9673,
  400 : #268055,
  500 : #006937,
  600 : #006131,
  700 : #00562a,
  800 : #004c23,
  900 : #003b16,
  A100 : #71ff95,
  A200 : #3eff6f,
  A400 : #0bff49,
  A700 : #00f13e,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-4c: (
  50 : #e6e5e4,
  100 : #c1bebb,
  200 : #98938e,
  300 : #6e6761,
  400 : #4f473f,
  500 : #30261d,
  600 : #2b221a,
  700 : #241c15,
  800 : #1e1711,
  900 : #130d0a,
  A100 : #ff8f57,
  A200 : #ff6d24,
  A400 : #f05000,
  A700 : #d64700,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


$nestle-primary-palette: mat.define-palette($md-349c, 400);
$nestle-accent-palette: mat.define-palette($md-369c, 50);
$nestle-warn-palette: mat.define-palette(mat.$red-palette);

$nestle-theme: mat.define-light-theme(
    $nestle-primary-palette,
    $nestle-accent-palette,
    $nestle-warn-palette);
