@mixin sanofi_logo() {
  .company-logo {
    margin-left: 1rem;
    height: 2rem;
    width: auto;
  }

  #company-brand {
    align-items: baseline !important;
  }
}

