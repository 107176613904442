@use '@angular/material' as mat;

@mixin components-mixin($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary));
  $accent: mat.get-color-from-palette(map-get($theme, accent));
  $warn: mat.get-color-from-palette(map-get($theme, warn));

  .border-primary:not(.mat-button-disabled) {
    border: 1px solid $primary;
  }

  .border-accent:not(.mat-button-disabled) {
    border: 1px solid mat.get-color-from-palette(map-get($theme, accent));
  }

  .border-accent-500:not(.mat-button-disabled) {
    border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 500);
  }

  .border-warn:not(.mat-button-disabled) {
    border: 1px solid $warn;
  }

  .barchart-primary-label {
    stroke: $primary;
    stroke-width: 1 !important;
  }

  .barchart-accent-background-label {
    fill: none;
  }

  .fill-accent-500 {
    fill: mat.get-color-from-palette(map-get($theme, accent), 500);
  }

  .stroke-accent-500 {
    stroke: mat.get-color-from-palette(map-get($theme, accent), 500);
  }

  .fill-primary {
    fill: mat.get-color-from-palette(map-get($theme, primary));
  }

  .stroke-primary {
    stroke: $primary;
  }

  .color-primary {
    color: $primary;
  }

  .color-primary-important {
    color: $primary !important;
  }

  .color-accent {
    color: $accent;
  }

  .color-accent-500 {
    color: mat.get-color-from-palette(map-get($theme, accent), 500) !important;
  }

  .color-disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  .background-primary {
    background-color: $primary;
  }

  .background-accent {
    background-color: $accent !important;
  }

  .background-accent-500 {
    background-color: mat.get-color-from-palette(map-get($theme, accent), 500);
  }

  .hover-background-primary {
    &:hover {
      background-color: $primary !important;
    }
  }

  .before-border-primary {
    &:before {
      border: 1px solid $primary;
    }
  }

  .color-warn {
    color: $warn;
  }

  a.active {
    font-weight: 500;
    color: $primary;
  }

  .hover-primary-shadow {
    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 5px -1px $primary,
      -1px -1px 5px -1px $primary;
    }
  }

  .primary-shadow {
    border: 1px solid $primary !important
  }

  .hover-accent-shadow {
    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 5px 2px mat.get-color-from-palette(map-get($theme, accent), 300),
      -1px -1px 5px 1px $accent;
    }
  }
}

