@mixin sanofi-fonts() {
  body > * {
    font-family: Arial, sans-serif !important;
  }

  *:not(mat-icon) {
    font-family: Arial, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Arial, sans-serif;
    font-weight: 400;
    margin: 0 !important;
    word-wrap: anywhere;
  }

  .action-button {
    * {
      font-family: Arial, sans-serif !important;
    }
  }
}
