// Default fonts
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy'), url('../assets/fonts/GilroyLight/gilroylight.woff') format('woff'),
  url('../assets/fonts/GilroyLight/gilroylight.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy'), url('../assets/fonts/GilroyRegular/gilroyregular.woff') format('woff'),
  url('../assets/fonts/GilroyRegular/gilroyregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: local('Gilroy'), url('../assets/fonts/GilroyRegular/gilroyregular.woff') format('woff'),
  url('../assets/fonts/GilroyRegular/gilroyregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy'), url('../assets/fonts/GilroyRegular/gilroyregular.woff') format('woff'),
  url('../assets/fonts/GilroyRegular/gilroyregular.woff2') format('woff2');
}


// Bel fonts
@font-face {
  font-family: 'Hello Headline Regular';
  font-style: normal;
  font-weight: 400;
  src: local('HelloHeadlineRegular'), url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff') format('woff'),
  url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Hello Headline Regular';
  font-style: normal;
  font-weight: 500;
  src: local('HelloHeadlineRegular'), url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff') format('woff'),
  url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Hello Headline Regular';
  font-style: normal;
  font-weight: 600;
  src: local('HelloHeadlineRegular'), url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff') format('woff'),
  url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Hello Headline Regular';
  font-style: normal;
  font-weight: 700;
  src: local('HelloHeadlineRegular'), url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff') format('woff'),
  url('../assets/fonts/HelloHeadlineRegular/helloheadlineregular.woff2') format('woff2');
}

// Delhaize fonts
@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 400;
  src: local('EuclidFlex'), url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf'),
  url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 500;
  src: local('EuclidFlex'), url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf'),
  url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 600;
  src: local('EuclidFlex'), url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf'),
  url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 700;
  src: local('EuclidFlex'), url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf'),
  url('../assets/fonts/delhaize/EuclidFlex-Regular.ttf') format('ttf');
}


// Kraft Heinz fonts
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham'), url('../assets/fonts/Gotham/GothamMedium.ttf') format('ttf'),
  url('../assets/fonts/Gotham/GothamMedium_1.ttf') format('ttf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: local('Gotham'), url('../assets/fonts/Gotham/GothamBold.ttf') format('ttf')
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: local('Gotham'), url('../assets/fonts/Gotham/GothamBold.ttf') format('ttf')
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: local('Gotham'), url('../assets/fonts/Gotham/GothamBold.ttf') format('ttf')
}


