@use '@angular/material' as mat;

@import 'variables';
@import 'custom-material-theme';
@import 'components-mixin';
@import 'default/fonts-mixin';
@import 'src/styles/default/theme';

$default-typography: mat.define-typography-config($font-family: '"Gilroy", Roboto, sans-serif');

@include mat.all-component-typographies($default-typography);
@include mat.core($default-typography);
@include mat.all-component-themes($qristo-theme);

@include custom-material-theme($qristo-theme);
@include components-mixin($qristo-theme);
@include default-fonts();

/*
  ARLA
 */
@import 'src/styles/arla/logo';
@import 'src/styles/arla/theme';
.M0kUHLkj, .QrREjtNN {
  @include mat.all-component-themes($arla-theme);
  @include custom-material-theme($arla-theme);
  @include components-mixin($arla-theme);
  @include arla-logo();
}

/*
  SWAROVSKI
 */
@import 'src/styles/swarovski/logo';
@import 'src/styles/swarovski/theme';
.Yssun93V, [class = '3mBM75zg'] {
  @include mat.all-component-themes($swarovski-theme);
  @include custom-material-theme($swarovski-theme);
  @include components-mixin($swarovski-theme);
  @include swarovski-logo();
}

/*
  NESTLE
 */
@import 'src/styles/nestle/logo';
@import 'src/styles/nestle/theme';
[class = '8yLwEEnm'], .OhI2qPRm {
  @include mat.all-component-themes($nestle-theme);
  @include custom-material-theme($nestle-theme);
  @include components-mixin($nestle-theme);
  @include nestle_logo()
}

/*
  COSTA COFFEE
 */
@import 'src/styles/costa-coffee/logo';
@import 'src/styles/costa-coffee/theme';
.pwvRmden, .snUyW82a {
  @include mat.all-component-themes($costa-coffee-theme);
  @include custom-material-theme($costa-coffee-theme);
  @include components-mixin($costa-coffee-theme);
  @include costa_coffee_logo;
}

/*
  KDP
 */
@import 'src/styles/kdp/logo';
@import 'src/styles/kdp/theme';
.poraEtyj, .sXJ9crKZ {
  @include mat.all-component-themes($kdp-theme);
  @include custom-material-theme($kdp-theme);
  @include components-mixin($kdp-theme);
  @include kdp-logo;
}

/*
  DECHRA
 */
@import 'src/styles/dechra/logo';
.WNrJnRNv, .g4ZKmX1h {
  @include dechra-logo;
}

/*
  REMY COINTREAU
 */
@import 'src/styles/remy-cointreau/logo';
@import 'src/styles/remy-cointreau/theme';
.Z5TsWt6L, .khYGW2Tp {
  @include mat.all-component-themes($remy-cointreau-theme);
  @include custom-material-theme($remy-cointreau-theme);
  @include components-mixin($remy-cointreau-theme);
  @include remy_cointreau_logo;
}

/*
  PANZANI
 */
@import 'src/styles/panzani/logo';
@import 'src/styles/panzani/theme';
.cbPa6QjD {
  @include mat.all-component-themes($panzani-theme);
  @include custom-material-theme($panzani-theme);
  @include components-mixin($panzani-theme);
  @include panzani_logo;
}

/*
  KRAFTHEINZ
 */
@import 'src/styles/kraftheinz/theme';
@import 'src/styles/kraftheinz/logo';
@import 'src/styles/kraftheinz/fonts-mixin';
.nE9Mc80U, .UrMKiebu {
  @include mat.all-component-themes($kraftheinz-theme);
  @include custom-material-theme($kraftheinz-theme);
  @include components-mixin($kraftheinz-theme);
  @include kraftheinz-fonts();
  @include kraftheinz-logo();
}

/*
  MOET-HENESSY
 */
@import 'src/styles/moet-hennessy/logo';
@import 'src/styles/moet-hennessy/theme';
.BuIoEvPY, .mG0gKYFl, .JfVL7eoA {
  @include mat.all-component-themes($mh-theme);
  @include custom-material-theme($mh-theme);
  @include components-mixin($mh-theme);
  @include default-fonts();
  @include moet-hennessy-logo();
}

/*
  BEL
 */
@import 'src/styles/bel/fonts-mixin';
@import 'src/styles/bel/logo';
@import 'src/styles/bel/theme';
[class = '0XsFbZBX'], .vAxtCK6D {
  @include mat.all-component-themes($bel-theme);
  @include custom-material-theme($bel-theme);
  @include components-mixin($bel-theme);
  @include bel-fonts();
  @include bel-logo();
}

/*
  DELHAIZE
 */
@import 'src/styles/delhaize/theme';
@import 'src/styles/delhaize/fonts-mixin';
@import 'src/styles/delhaize/logo';
.QppJNFQ2, [class = '9IrzK9pg'], .vUBpXZjzi {
  @include mat.all-component-themes($delhaize-theme);
  @include custom-material-theme($delhaize-theme);
  @include components-mixin($delhaize-theme);
  @include delhaize-fonts();
  @include delhaize-logo();
}

/*
  SANOFI
*/
@import 'src/styles/sanofi/logo';
@import 'src/styles/sanofi/theme';
@import 'src/styles/sanofi/fonts-mixin';
[class = '2lNg3SwD'], .VZsIcIj2 {
  @include mat.all-component-themes($sanofi-theme);
  @include custom-material-theme($sanofi-theme);
  @include components-mixin($sanofi-theme);
  @include sanofi-fonts();
  @include sanofi-logo();
}

/*
  BONDUELLE
 */
@import 'src/styles/bonduelle/theme';
@import 'src/styles/bonduelle/logo';
.X9KODk6b, .ZoW6HvrD {
  @include mat.all-component-themes($bonduelle-theme);
  @include custom-material-theme($bonduelle-theme);
  @include components-mixin($bonduelle-theme);
  @include default-fonts();
  @include bonduelle_logo();
}

@import 'src/styles/silgan/theme';
@import 'src/styles/silgan/logo';
.cJlpZ4f6, .m6mbYcSM {
  @include mat.all-component-themes($silgan-theme);
  @include custom-material-theme($silgan-theme);
  @include components-mixin($silgan-theme);
  @include silgan-logo();
  @include default-fonts();

}

/*
  LACTALIS
 */
@import 'src/styles/lactalis/theme';
@import 'src/styles/lactalis/logo';
@import 'src/styles/lactalis/fonts-mixin';
.zrmxgglX, .Bz58bnFd, .AasglBHF {
  @include mat.all-component-themes($lactalis-theme);
  @include custom-material-theme($lactalis-theme);
  @include components-mixin($lactalis-theme);
  @include lactalis-fonts();
  @include lactalis-logo();
}

/*
  DANONE
 */
@import 'src/styles/danone/theme';
@import 'src/styles/danone/logo';
.CWapDRyT, .DTpPFImN {
  @include mat.all-component-themes($danone-theme);
  @include custom-material-theme($danone-theme);
  @include components-mixin($danone-theme);
  @include danone-logo();
}
